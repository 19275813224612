.link {
  color: #6e6e6e;
  text-decoration: none;
}

.landingPage {
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;
}

.overview {
  text-align: center;
}

.landingLogo {
  width: 45%;
}

.signUpLogIn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.langindPageBtnDiv {
  margin-left: 1rem;
  margin-right: 1rem;
}

.landingPageP {
  text-align: center;
}

.landingPageLink {
  text-decoration: none;
  color: white;
}

.about {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 5rem;
  text-align: justify;
  text-align-last: center;
}

.bottomNavImg {
  width: 2rem;
}

@media (min-width: 600px) {
  .landingPage {
    width: 50%;
  }
  .logo.MuiBox-root {
    display: block;
  }
  .logout.MuiBox-root {
    display: block;
  }
  .hidden.MuiBox-root {
    display: none;
  }
  .landingLogo {
    width: 50%;
  }
}

@media (min-width: 900px) {
  .landingLogo {
    width: 35%;
  }
  .logo.MuiBox-root {
    display: block;
  }
  .logout.MuiBox-root {
    display: block;
  }
  .hidden.MuiBox-root {
    display: none;
  }
  .css-1t6c9ts {
    justify-content: flex-end;
  }
}
