.libraryDiv {
  max-height: 70vh;
  overflow: auto;
}

.library {
  width: 35%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.description {
  max-width: 40%;
  align-self: center;
  margin-top: 0;
  margin-bottom: 0;
  max-height: 75px;
  overflow: auto;
}

.title {
  align-self: center;
  margin-top: 0;
  margin-bottom: 0;
}

.addlibrary {
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  width: 60px;
}

.addBook {
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  width: 40px;
}

.header {
  text-align: center;
  width: 90%;
}

.pencil {
  width: 20px;
}

.gridListLibrary {
  max-width: 25px;
  max-height: 25px;
  opacity: 0.7;
}

.gridListLibraryOverview {
  width: 25px;
  float: right;
  opacity: 0.7;
}

.cardLink {
  color: #1bae9f;
}

.cardDiv,
.books {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.card {
  margin: 0.5rem;
}

.MuiCardContent-root.cardContent,
.MuiCardContent-root.cardContent:last-child,
.MuiCardActions-root.cardContent {
  padding: 0;
  text-align: center;
  align-items: center;
}

.libraryHeader,
.libraryDetails {
  display: flex;
}

.libraryHeader {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
  justify-content: space-between;
}

.libraryDetails {
  margin-left: 1rem;
  align-items: center;
}

.bookLink {
  font-size: 12px;
  padding: 0;
  text-decoration: underline;
}

.textfieldCenter {
  display: flex;
  justify-content: center;
}

.textfieldCenter > div {
  margin-top: 1rem;
  margin-bottom: 0;
}

@media (min-width: 900px) {
  .addlibrary,
  .addBook {
    right: 15%;
  }
}

@media (min-width: 1400px) {
  .addlibrary,
  .addBook {
    right: 30%;
  }
}
