.bookOverviewDiv {
  max-height: 70vh;
  overflow: auto;
}

.bookHeader {
  text-align: center;
  width: 90%;
}

.bookDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.cover {
  display: block;
  width: 30%;
  margin-right: auto;
  margin-left: auto;
}

.heading {
  margin-top: 1rem;
  margin-bottom: 0;
}

.bookDetail {
  margin: 2rem 2rem 0 2rem;
  padding-bottom: 0.5rem;
  overflow: auto;
  max-height: 65vh;
}

.back {
  position: fixed;
  bottom: 5rem;
  left: 2rem;
  width: 40px;
}

.delete {
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  width: 30px;
}

.reviewDelete {
  width: 15px;
}

.gridList {
  width: 25px;
  float: right;
  opacity: 0.7;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
  padding-right: 24px;
}

#status-label,
#status,
#status-listbox,
#panel1a-header
  > div.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content
  > p,
#panel1a-header
  > div.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content
  > p {
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.bookDetail
  > div.MuiAutocomplete-root.Mui-focused.MuiAutocomplete-hasPopupIcon.statusInput.css-yk43mc-MuiAutocomplete-root
  > div
  > div
  > fieldset {
  border-color: #1bae9f;
}

.MuiRating-root.booksRating,
#status-label.Mui-focused {
  color: #1bae9f;
}

.MuiPaper-root.paper {
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 0.5rem;
}

.listBox {
  flex-direction: column;
  align-items: center;
}

.listImgIcon {
  width: 20%;
  margin-right: 0.5rem;
}

.listImg {
  width: 100%;
}

@media (min-width: 400px) {
  .bookDetail {
    max-height: 70vh;
  }
}

@media (min-width: 900px) {
  .cover {
    width: 20%;
  }
  .back {
    left: 15%;
  }
  .delete {
    right: 15%;
  }
  .bookDetail {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1400px) {
  .cover {
    width: 15%;
  }
  .back {
    left: 30%;
  }
  .delete {
    right: 30%;
  }
  .bookDetail {
    max-width: 40vw;
  }
}
