.profile {
  margin: 2rem 2rem 0 2rem;
  padding-bottom: 0.5rem;
  overflow: auto;
  max-height: 65vh;
}

.link {
  color: #1bae9f;
  text-decoration: none;
}

.avatar {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  background-color: #dfe6ed;
}

.editAvatar {
  margin-left: auto;
  margin-right: auto;
  background-color: #dfe6ed;
}

.details,
.favoritesInput {
  width: 82%;
}

.addlibrary {
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  width: 75px;
}

.settings {
  position: fixed;
  bottom: 5rem;
  left: 2rem;
  width: 50px;
}

.delete {
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  width: 30px;
}

@media (min-width: 400px) {
  .details,
  .favoritesInput {
    width: 50%;
  }
  .profile {
    max-height: 70vh;
  }
}

@media (min-width: 900px) {
  .addlibrary,
  .delete {
    right: 15%;
  }
  .settings {
    left: 15%;
  }
  .details,
  .favoritesInput {
    width: 30%;
  }
  .profile {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
  .avatar {
    margin-top: 5rem;
  }
}

@media (min-width: 1400px) {
  .addlibrary,
  .delete {
    right: 30%;
  }
  .settings {
    left: 30%;
  }
  .details,
  .favoritesInput {
    width: 20%;
  }
  .profile {
    max-width: 40vw;
  }
  .avatar {
    margin-top: 5rem;
  }
}
