body {
  background-color: #c3cfd9;
}

.loading {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 25%;
  left: 10%;
  right: 10%;
}

.loading p {
  font-size: 24px;
  margin-top: 0;
}
